import React from "react";
import { provideMapState } from "react-openlayers";
import { PropTypes } from "prop-types";
import Icon from "./Icon";

class FullScreenButton extends React.Component {
  componentDidUpdate(lastProps) {
    if (lastProps.fullscreen !== this.props.fullscreen) {
      this.props.forceUpdateMap();
    }
  }

  render() {
    const { className, fullscreen, onClick, tooltip } = this.props;

    return (
      <div
        className={`fullscreen ${className}`}
        onClick={onClick}
        title={tooltip}
      >
        <Icon name={fullscreen ? "contract" : "expand"} />
      </div>
    );
  }
}

FullScreenButton.propTypes = {
  forceUpdateMap: PropTypes.func.isRequired,
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default provideMapState(FullScreenButton);
