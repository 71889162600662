import React from "react";

/**
 * Higher order component (HOC) that allows any other component to monitor view
 * state.
 *
 * Provides props viewWidth, viewHeight.
 */
export function provideViewSize(WrappedComponent) {
  return class extends React.Component {
    constructor() {
      super();
      this.handleViewSizeChange = this.handleViewSizeChange.bind(this);
      this.state = {
        viewWidth: window.innerWidth,
        viewHeight: window.innerHeight
      };
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleViewSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleViewSizeChange);
    }

    handleViewSizeChange() {
      this.setState({
        viewWidth: window.innerWidth,
        viewHeight: window.innerHeight
      });
    }

    render() {
      return (
        <WrappedComponent
          viewHeight={this.state.viewHeight}
          viewWidth={this.state.viewWidth}
          {...this.props}
        />
      );
    }
  };
}
