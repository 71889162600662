const en = {
  locale: "en",

  App: {
    locale: "en",
    selectMap: "Choose map",
  },

  CoreLayout: {
    fullscreenTip: "Show only map",
  },

  Source: {
    ladestrom: "Ferry charge",
    lng: "LNG",
    landstrom: "Shore power",
    metanol: "Methanol",
  },

  ArticleView: {
    title: "Facilities: Kystverket",
    searchPlaceholder: "Search facilities",
  },

  Article: {
    articleImage: "Article image",
    note: "Comment: ",
    owner: "Owner: ",
    operator: "Operator: ",
    contact: "Contact info: ",
    typeOfFacility: "Type of facility: ",
    availability: "Availability: ",
    capacity: "Capacity: ",
    transferSpeed: "Transfer speed: ",
    depth: "Depth: ",
    length: "Length: ",
    notAvailable: "(no info)",
    availabilityPublic: "Publicly available",
    availabilityPrivate: "Exclusively available",
    availabilityUnknown: "Unknown availability",
    anlegg: "Facility",
    eier: "Owner",
    nettselskap: "Grid company: ",
    tilgang: "Access",
    spenning: "Voltage (V)",
    effekt: "Power (kW)",
    effectCalculated:
      "(*) Power is originally given in kVA and has been calculated with the factor 1 kVA = 0.9 kW.",
    frekvens: "Frequency (Hz)",
    antall_tilkoblingspunkt: "Connectors",
    antall_skip_som_kan_betjenes_i_parallell:
      "No. of vessels that can be connected simultaneously.",
    dybde_ved_kai: "Depth at quay (m)",
    kailengde: "Quay length (m)",
    dybde: "Depth at quay (m)",
    lengde: "Quay length (m)",
    epost: "E-mail",
    telefon: "Phone",
    kapasitet: "Storage capacity (m3)",
    overforingshastighet: "Transfer rate (m3/hour)",
  },

  PageView: {
    searchPlaceholder: "Search facilities",
    pageNotFound: "Page not found",
    checkAddress: "Please check the address or",
    homePage: "go to home page",
  },

  MapLayout: {
    zoomInTipLabel: "Zoom in",
    zoomOutTipLabel: "Zoom out",
    trackTipLabel: "My location",
  },

  MapLayers: {
    selectMap: "Select map layers",
    baseMap: "Grey map",
    seaMap: "Sea map",
    enc: "ENC",
    norwayInPictures: "Norge i bilder",
  },
};

export default en;
