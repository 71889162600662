import React from "react";
import PropTypes from "prop-types";
import Search from "../components/Search";
import Title from "../components/Title";
import ArticleListComponent from "../components/ArticleListComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Articles,
  articleActions,
  ensureArticleModuleConfig,
} from "react-article-module";
import { withRouter } from "react-router-dom";
import { provideMapState } from "react-openlayers";
import { updateMapHistory } from "../redux/mapHistory";
import { translate } from "react-translate";
import config from "../config/kystverket";

import "./ArticleView.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    articleActions: bindActionCreators(
      Object.assign({}, articleActions),
      dispatch
    ),
    updateMapHistory: bindActionCreators(updateMapHistory, dispatch),
  };
};

const mapStateToProps = (state) => ({});

class ArticleView extends React.Component {
  constructor() {
    super();

    this.onArticleOpen = this.onArticleOpen.bind(this);
    this.onSourceSelect = this.onSourceSelect.bind(this);
  }

  onArticleOpen(article) {
    const { history, mapCenter, mapZoom } = this.props;
    const prefix = config.site === 4 ? "/anlegg/" : "/en/facilities/";
    history.push(prefix + article.id.replace("_", "/"));
    window.scroll(0, 0);

    this.props.updateMapHistory(mapZoom, mapCenter);
  }

  onSourceSelect() {}

  get activeSources() {
    const { sources } = this.props;
    return sources.map((item) => item.dataIndex);
  }

  render() {
    const activeSources = this.activeSources;
    const { mapZoom, children, t } = this.props;
    return (
      <div className="article-view--container">
        <Title title={t("title")} />
        <div
          className="article-view--content artwork--container"
          id="articleView-scrollPane"
        >
          <Search placeholder={t("searchPlaceholder")} />
          {mapZoom > 2 && (
            <div className="content">
              <Articles
                activeSources={activeSources}
                articleComponent={ArticleListComponent}
                hideSelector
                hideLayers
                preventLoad
                onSourceSelect={this.onSourceSelect}
                onArticleOpen={this.onArticleOpen}
              />
            </div>
          )}
        </div>
        {children}
      </div>
    );
  }
}

ArticleView.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired,
  mapZoom: PropTypes.number.isRequired,
  children: PropTypes.node,
  mapCenter: PropTypes.array.isRequired,
  updateMapHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    ensureArticleModuleConfig(
      provideMapState(translate("ArticleView")(ArticleView))
    )
  )
);
