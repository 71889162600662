import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import { titlePrefix } from "./SourceConfig";
import "./ArticleListComponent.scss";
import config from "../config/kystverket";

class ArticleListComponent extends React.Component {
  render() {
    const {
      onArticleOpen,
      thumbnailClassName,
      thumbnail,
      article,
      loadImage,
      validTitle,
    } = this.props;

    const sourceDataIndex =
      article.sourceDataIndex || article.sources[0] || false;

    const noImage =
      config.site === 4 ? "/images/ingenbilde.jpg" : "/images/noimage.jpg";

    return (
      <div
        className={
          thumbnailClassName +
          " articleList--withThumbnail" +
          " articleList--entry clearfix"
        }
        onMouseOver={() => article.olFeature.set("hover", true)}
        onMouseOut={() => article.olFeature.set("hover", false)}
        onClick={(e) => {
          e.preventDefault();
          onArticleOpen(article);
        }}
      >
        {loadImage ? (
          <img
            alt=""
            data-original={thumbnail || noImage}
            src={thumbnail || noImage}
            className="loaded"
          />
        ) : (
          <img alt="" data-original={thumbnail || noImage} />
        )}
        <div className="caption">
          {sourceDataIndex && <Icon name={sourceDataIndex} />}

          {validTitle(article.properties.title) && (
            <h4>
              {titlePrefix(sourceDataIndex).toUpperCase()}
              {article.properties.title}
            </h4>
          )}

          <span className="articleList--content">
            {article.properties.kommunenavn}
          </span>
        </div>
      </div>
    );
  }
}

ArticleListComponent.propTypes = {
  onArticleOpen: PropTypes.func.isRequired,
  thumbnailClassName: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  article: PropTypes.object.isRequired,
  validTitle: PropTypes.func.isRequired,
  loadImage: PropTypes.bool,
};

export default ArticleListComponent;
