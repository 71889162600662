import React from "react";
import PropTypes from "prop-types";
import { ts } from "../../translations/translations";

function formatValue(value) {
  if (/^[\d ]+$/.test(value)) {
    const int = parseInt(value.replace(" ", ""));
    if (!isNaN(int)) {
      return int.toLocaleString(ts("App", "locale"));
    }
  }
  return value;
}

/* eslint-disable camelcase */
const ArticleAdditionalColumn = ({
  article,
  value,
  name,
  column_name,
  data_type
}) => (
  <div className="additionalColumn">
    <span className="additionalColumnLabel">
      {ts("Article", column_name) || name}
    </span>
    {
      <span
        className={["additionalColumnContent", data_type].join(" ")}
        dangerouslySetInnerHTML={{
          __html: value ? formatValue(value) : ts("Article", "notAvailable")
        }}
      />
    }
    {column_name === "effekt" && article.properties.effekt_beregnet && (
      <em> (*)</em>
    )}
  </div>
);
/* eslint-enable camelcase */

ArticleAdditionalColumn.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  data_type: PropTypes.string.isRequired
};

export default ArticleAdditionalColumn;
