import React from "react";
import Title from "./Title";
import PropTypes from "prop-types";
import { olUtils } from "react-openlayers";
import { translate } from "react-translate";
import { titlePrefix } from "./SourceConfig";
import "./Article.scss";
import config from "../config/kystverket";

class Article extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.source !== this.props.source) {
      this.setState({ items: [] });
    }
  }

  extractHref(s) {
    if (s.indexOf("http") === 0) return s;
    const m = /<a.*href="?([^\s]+?)"? .*>/i.exec(s);
    if (m && m.length > 1) return m[1];
  }

  showAdditionalColumn(column) {
    switch (column) {
      case "kommunenummer":
      case "kommunenavn":
      case "anlegg":
      case "nettselskap":
      case "eier":
      case "eier_en":
      case "operator":
      case "telefon":
      case "epost":
      case "kontaktperson":
      case "terminal":
      case "anleggseier":
      case "tilgjengelighet":
      case "tilgang":
      case "bilde":
      case "effekt_beregnet":
      case "type":
        return false;

      case "tilkoblingstype":
        return config.site === 4;
      case "tilkoblingstype_en":
        return config.site === 5;

      default:
        break;
    }
    return true;
  }

  // This function returns the coordinate
  // conversion string in DD to DMS.
  degToDms() {
    const deg = olUtils.transformCoordinate(
      this.props.article.geometry.coordinates,
      "EPSG:3857",
      "EPSG:4326"
    );

    let lat = deg[1];
    let lng = deg[0];

    let latResult, lngResult, dmsResult;

    lat = parseFloat(lat);
    lng = parseFloat(lng);

    latResult = lat >= 0 ? "N: " : "S: ";
    latResult += this.getDms(lat);

    lngResult = lng >= 0 ? "E: " : "W: ";
    lngResult += this.getDms(lng);

    dmsResult = latResult + " " + lngResult;

    return dmsResult;
  }

  getDms(val) {
    let valDeg, valMin, valSec, result;

    val = Math.abs(val);

    valDeg = Math.floor(val);
    result = valDeg + "º";

    valMin = Math.floor((val - valDeg) * 60);
    result += valMin + "'";

    valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
    result += valSec + '"';

    return result;
  }
  renderTilgjengelighet() {
    const { tilgjengelighet, tilgang, t } = this.props;

    const availability = tilgjengelighet || tilgang;
    if (!availability) return null;

    switch (availability) {
      case "Offentlig":
        return (
          <p className="article--availability">{t("availabilityPublic")}</p>
        );
      case "Privat":
        return (
          <p className="article--availability">{t("availabilityPrivate")}</p>
        );
      default:
        return (
          <p className="article--availability">{t("availabilityUnknown")}</p>
        );
    }
  }

  render() {
    const {
      children,
      title,
      content,
      eier,
      eier_en,
      source,
      image,
      terminal,
      kommunenavn,
      article,
      operator,
      nettselskap,
      telefon,
      epost,
      effekt_beregnet,
      t,
    } = this.props;

    const fullTitle = titlePrefix(source.dataIndex) + title;

    const noImage =
      config.site === 4 ? "/images/ingenbilde.jpg" : "/images/noimage.jpg";

    return (
      <div className="article">
        <Title title={fullTitle} />
        {image ? (
          <img
            src={image.replace("thumbnail_size=large", "thumbnail_size=medium")}
            alt=""
          />
        ) : (
          <img src={noImage} alt="" />
        )}
        {title && title !== "null" && (
          <div className="article--title">
            <h3>{fullTitle}</h3>
            {terminal && <p>{terminal}</p>}
            {this.renderTilgjengelighet()}
            <p>
              <span className="article--label">
                {kommunenavn.toUpperCase()}{" "}
              </span>
              <span>({article.geometry.coordinates && this.degToDms()})</span>
            </p>
          </div>
        )}

        {children && children.length && (
          <div className="additionalColumns">
            {children.filter((e) =>
              this.showAdditionalColumn(e.key, e.props.article)
            )}
          </div>
        )}

        {(telefon || epost) && (
          <div className="article--content-contact">
            <span>
              <span className="article--label">{t("contact")}</span>
              {telefon ? telefon + ", " : ""}
              {epost && (
                <a href={`mailto:${epost}`} target="_top">
                  {epost}
                </a>
              )}
            </span>
          </div>
        )}

        <div className="article--content-footer">
          <span className="article--label">
            {t("owner")}{" "}
            {config.site === 5 ? eier_en || eier || content : eier || content}
          </span>
          {content && (operator || nettselskap) && " | "}
          {operator ? (
            <span className="article--label">
              {t("operator")} {operator}
            </span>
          ) : nettselskap ? (
            <span className="article--label">
              {t("nettselskap")} {nettselskap}
            </span>
          ) : null}
        </div>

        {effekt_beregnet && (
          <div className="article--note">{t("effectCalculated")}</div>
        )}
      </div>
    );
  }
}

Article.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  source: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  provider: PropTypes.string,
  article: PropTypes.object,
  image: PropTypes.string,
  terminal: PropTypes.string,
  kommunenavn: PropTypes.string,
  operator: PropTypes.string,
  nettselskap: PropTypes.string,
  kontaktperson: PropTypes.string,
  telefon: PropTypes.string,
  epost: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default translate("Article")(Article);
