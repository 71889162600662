const no = {
  locale: "no",

  App: {
    locale: "nb-NO",
  },

  CoreLayout: {
    fullscreenTip: "Vis kart i fullskjerm",
  },

  Source: {
    ladestrom: "Ladeanlegg ferjer",
    lng: "LNG",
    landstrom: "Landstrøm",
    metanol: "Metanol",
  },

  ArticleView: {
    title: "Anlegg: Kystverket",
    searchPlaceholder: "Søk etter anlegg",
  },

  Article: {
    articleImage: "Article bilde",
    note: "Merknad: ",
    owner: "Eier: ",
    operator: "Operatør: ",
    contact: "Kontaktinfo: ",
    typeOfFacility: "Type anlegg: ",
    availability: "Tilgjengelighet: ",
    capacity: "Kapasitet: ",
    transferSpeed: "Overføringshastighet: ",
    depth: "Dybde: ",
    length: "Lengde: ",
    nettselskap: "Nettselskap: ",
    notAvailable: "(ingen info)",
    availabilityPublic: "Offentlig tilgjengelig",
    availabilityPrivate: "Eksklusivt tilgjengelig",
    availabilityUnknown: "Ukjent tilgjengelighet",
    effectCalculated:
      "(*) Effekt er opprinnelig oppgitt i kVA og er omregnet med effektfaktoren 1 kVA = 0,9 kW.",
  },

  PageView: {
    searchPlaceholder: "Søk etter anlegg",
    pageNotFound: "Siden ble ikke funnet",
    checkAddress: "Vennligst kontroller adressen eller",
    homePage: "gå til hjemmesiden",
  },

  MapLayout: {
    zoomInTipLabel: "Zoom inn",
    zoomOutTipLabel: "Zoom ut",
    trackTipLabel: "Min posisjon",
  },

  MapLayers: {
    selectMap: "Velg kartlag",
    baseMap: "Grått bakgrunnskart",
    seaMap: "Sjokart",
    enc: "ENC",
    norwayInPictures: "Norge i bilder",
  },
};

export default no;
