import { bindActionCreators as originalBindActionCreator } from "redux";

const prefix = key => `scoped(${key})@@`;

const wrapAction = (action, reducerKey) => {
  return {
    ...action,
    type: prefix(reducerKey) + action.type
  };
};

const wrapDispatch = (dispatch, reducerKey) => {
  const wrappedDispatch = action => {
    let wrappedAction;
    if (typeof action === "function") {
      wrappedAction = (globalDispatch, getState, extraArgument) =>
        action(
          wrappedDispatch,
          getState,
          globalDispatch,
          reducerKey,
          extraArgument
        );
    } else if (typeof action === "object") {
      wrappedAction = wrapAction(action, reducerKey);
    }
    return dispatch(wrappedAction);
  };
  return wrappedDispatch;
};

/**
 * Bind action creators for a wrapped reducer.
 *
 * @param {Object} actionCreators The action creator object, will be passed on to standard bindActionCreators.
 * @param {Function} dispatch The dispatch function.
 * @param {String} reducerKey The unique ID to prefix reducer actions with (scope). Must match what's provided to wrapReducer.
 */
export const bindActionCreators = (actionCreators, dispatch, reducerKey) => {
  const wrappedDispatch = wrapDispatch(dispatch, reducerKey);
  return originalBindActionCreator(actionCreators, wrappedDispatch);
};

/**
 * Wraps any reducer in a scope to allow multiple instances of the same reducer in
 * a reducer tree.
 *
 * A caveat is that all actions handled by the reducer must be scoped, which means
 * it will not receive actions belonging to other reducers (which may be desired).
 *
 * This wrapper is therefore only suitable for self contained reducers with no
 * knowledge or dependency on other reducers.
 *
 * Use bindActionCreators when binding actions with the same key as provided to this
 * function to communicate with the wrapped reducer.
 *
 * State map functions can then reference the state of the wrapped reducer as if it
 * was not wrapped.
 *
 * @param {Function} reducer The reducer function to wrap.
 * @param {String} reducerKey The unique ID to prefix reducer actions with (scope).
 */
export const wrapReducer = (reducer, reducerKey) => (state, action) => {
  if (!state) {
    return reducer(state, action);
  } else {
    if (action.type.indexOf(prefix(reducerKey)) === 0) {
      const unwrappedAction = {
        ...action,
        type: action.type.split("@@")[1]
      };
      return reducer(state, unwrappedAction);
    }
    return state;
  }
};
