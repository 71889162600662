import React from "react";
import Icon from "./Icon";
import PropTypes from "prop-types";
import { titlePrefix } from "./SourceConfig";

class SearchResult extends React.Component {
  render() {
    const { source, result, onResultSelected } = this.props;
    return (
      <li
        key={result.id}
        className="searchResult--container clearfix"
        onClick={() => onResultSelected(result)}
        onMouseOver={() =>
          result.olFeature && result.olFeature.set("hover", true)
        }
        onMouseOut={() =>
          result.olFeature && result.olFeature.set("hover", false)
        }
      >
        <Icon name={source.dataIndex} />
        <h3>
          {titlePrefix(source.dataIndex)}
          {result.properties.title}
        </h3>
        {result.properties.content && (
          <p className="searchSource--content">
            {result.properties.content.replace(/<.*?>/g, "").substring(0, 150)}
          </p>
        )}
        <p className="searchSource--provider">{result.properties.provider}</p>
      </li>
    );
  }
}

SearchResult.propTypes = {
  source: PropTypes.object,
  result: PropTypes.object,
  onResultSelected: PropTypes.func.isRequired
};

export default SearchResult;
