import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  provideMapState,
  ClusterSource,
  clusterStackStyle,
  clusterText,
  Select,
} from "react-openlayers";
import {
  articleActions,
  ensureArticleModuleConfig,
} from "react-article-module";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import { icons, titlePrefix } from "./SourceConfig";

import "./ArticleFeatureRenderer.scss";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, articleActions), dispatch);

const mapStateToProps = (state) => ({
  articles: state.articles.articles,
  articleLoadState: state.articles.loadState,
});

function getSource(key, sources) {
  let i, source;
  // Check for data index match
  for (i = 0; i < sources.length; ++i) {
    source = sources[i];
    if (source.dataIndex === key) {
      return source;
    }
  }
}

function articleStyle(feature) {
  return new Style({
    image: new Icon({
      src: icons[feature.get("dataIndex")],
      scale: 0.5,
      imgSize: [48, 48],
    }),
  });
}

function FeatureList({ features, onSelect, onDismiss }) {
  return (
    <div className="feature-list" onClick={onDismiss}>
      <ul>
        {features.map((f) => (
          <li key={f.ol_uid} onClick={() => onSelect(f)}>
            <img src={icons[f.get("dataIndex")]} alt="" />
            {titlePrefix(f.get("dataIndex")) + f.get("title")}
          </li>
        ))}
      </ul>
    </div>
  );
}

class ArticleFeatureRenderer extends React.Component {
  static propTypes = {
    loadSource: PropTypes.func.isRequired,
    activeSources: PropTypes.array,
    sources: PropTypes.array.isRequired,
    mapCenter: PropTypes.any,
    projCode: PropTypes.string,
    featureLimit: PropTypes.number,
    useActiveStyle: PropTypes.bool,
    useHoverStyle: PropTypes.bool,
    onArticleOpen: PropTypes.func.isRequired,
    filterColumns: PropTypes.object,
    articles: PropTypes.object,
    openArticleId: PropTypes.string,
  };

  static defaultProps = {
    filterColumns: {},
    activeSources: [],
  };

  state = {};

  componentDidMount() {
    this.checkSources(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.checkSources(nextProps);
  }

  checkSources(props) {
    this.activeSources.forEach((source) => {
      const loadState = props.articleLoadState[source];
      const {
        sources,
        mapCenter,
        mapZoom,
        mapExtent,
        projCode,
        filterColumns,
        loadSource,
      } = props;

      if (
        !(
          loadState &&
          (loadState.isLoaded || loadState.isLoading) &&
          loadState.mapCenter[0] === mapCenter[0] &&
          loadState.mapCenter[1] === mapCenter[1] &&
          loadState.mapZoom === mapZoom &&
          JSON.stringify(loadState.filterColumns) ===
            JSON.stringify(filterColumns)
        )
      ) {
        // Source is not loaded or outdated
        loadSource(
          getSource(source, sources),
          mapCenter,
          mapZoom,
          mapExtent,
          projCode,
          filterColumns,
          0
        );
      }
    });
  }

  get activeSources() {
    const { activeSources, sources } = this.props;
    if (!activeSources || !activeSources.length) {
      return sources
        .filter((s) => {
          return s.pressed && s.dataIndex;
        })
        .map((item) => item.dataIndex);
    }

    return activeSources;
  }

  get articles() {
    const { articles } = this.props;
    const activeSources = this.activeSources;

    const renderArticles = Object.values(articles).filter(
      (article) =>
        article.sources &&
        article.sources.some((source) => activeSources.indexOf(source) !== -1)
    );

    return renderArticles;
  }

  getFeatures(articles, source) {
    const { onArticleOpen, openArticleId } = this.props;
    const features = [];
    articles.forEach((article) => {
      if (
        (!source || article.sources[0] === source) &&
        article.olFeature !== undefined &&
        article.id !== openArticleId
      ) {
        const feature = article.olFeature;
        feature.set("dataIndex", article.sourceDataIndex || article.sources[0]);
        if (!feature.onClick) {
          feature.onClick = () => {
            onArticleOpen(article, true);
          };
        }
        features.push(feature);
      }
    });
    return features;
  }

  sortLayers(a, b) {
    const aPrio = a.zIndex || 0;
    const bPrio = b.zIndex || 0;
    return aPrio - bPrio;
  }

  onFeatureSelect = (selected) => {
    const cluster = selected[0];
    const { mapZoom } = this.props;
    const features = cluster.get("features");
    if (features.length === 1) {
      features[0].onClick();
    } else if (mapZoom < 14) {
      this.props.setCenter(
        cluster.getGeometry().getCoordinates(),
        Math.max(mapZoom + 1, 10)
      );
    } else {
      this.setState({
        presentedFeatures: features,
      });
    }
    return false;
  };

  render() {
    const articles = this.articles;
    const { presentedFeatures } = this.state;

    return (
      <React.Fragment>
        <ClusterSource
          features={this.getFeatures(articles)}
          clusterThreshold={50}
          selectable={false}
          layerName="ArticleLayers"
          styleFn={() =>
            clusterStackStyle({
              typeField: "dataIndex",
              clusterLabelText: clusterText({ color: "#333", offsetY: 1 }),
              stackOffsetX: -3,
              stackOffsetY: 5,
              featureStyle: articleStyle,
            })
          }
        >
          <Select onSelect={this.onFeatureSelect} hitTolerance={20} />
        </ClusterSource>
        {presentedFeatures && (
          <FeatureList
            features={presentedFeatures}
            onSelect={(f) => {
              this.setState({ presentedFeatures: null });
              f.onClick();
            }}
            onDismiss={() => this.setState({ presentedFeatures: null })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ensureArticleModuleConfig(provideMapState(ArticleFeatureRenderer)));
