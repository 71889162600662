import React from "react";
import "./Loading.scss";

class LoadingIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timer: null, displayLoadingIndicator: false };
  }

  componentWillMount() {
    const timer = setTimeout(() => {
      this.setState({ timer: null, displayLoadingIndicator: true });
    }, 50);
    this.setState({ timer });
  }

  componentWillUnmount() {
    const timer = this.state.timer;
    if (timer !== null) {
      clearTimeout(timer);
    }
  }

  render() {
    const { displayLoadingIndicator } = this.state;
    if (!displayLoadingIndicator) {
      return null;
    }
    return (
      <div className="loading-overlay">
        <div className="loading-indicator">
          <div className="loading-indicator-fading-circle">
            <div className="loading-indicator-circle1 loading-indicator-circle" />
            <div className="loading-indicator-circle2 loading-indicator-circle" />
            <div className="loading-indicator-circle3 loading-indicator-circle" />
            <div className="loading-indicator-circle4 loading-indicator-circle" />
            <div className="loading-indicator-circle5 loading-indicator-circle" />
            <div className="loading-indicator-circle6 loading-indicator-circle" />
            <div className="loading-indicator-circle7 loading-indicator-circle" />
            <div className="loading-indicator-circle8 loading-indicator-circle" />
            <div className="loading-indicator-circle9 loading-indicator-circle" />
            <div className="loading-indicator-circle10 loading-indicator-circle" />
            <div className="loading-indicator-circle11 loading-indicator-circle" />
            <div className="loading-indicator-circle12 loading-indicator-circle" />
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingIndicator;
