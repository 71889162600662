import React from "react";
import PropTypes from "prop-types";
import ArticleView from "../views/ArticleView";
import ArticleDetailView from "../views/ArticleDetailView";
import PageView from "../views/PageView";
import CoreLayout from "../layouts/CoreLayout";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import config from "../config/kystverket";

function checkLanguageChange() {
  var site = 4;
  if (
    window.location.pathname.length > 1 &&
    window.location.pathname.split("/")[1] === "en"
  )
    site = 5;
  if (site !== config.site) {
    window.location.reload();
  }
}

const browserHistory = createBrowserHistory({
  basename: config.basename,
});

browserHistory.listen(checkLanguageChange);
window.addEventListener("popstate", checkLanguageChange, false);

class AppRouter extends React.Component {
  componentDidMount() {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      splashScreen.remove();
    }
  }
  render() {
    const { children } = this.props;
    return (
      <Router history={browserHistory}>
        <CoreLayout>
          {children}
          <Switch>
            <Redirect from="/index" to="/" />
            <Route path="/en">
              <Switch>
                <Redirect from="/en/index" to="/en" />
                <Route path="/en/facilities">
                  <React.Fragment>
                    <Route
                      path="/en/facilities/:activeSources?"
                      component={ArticleView}
                    />
                    <Route
                      path="/en/facilities/:sourceId/:articleId"
                      component={ArticleDetailView}
                    />
                  </React.Fragment>
                </Route>
                <Route path="/*page" component={PageView} />
                <Route component={PageView} />
              </Switch>
            </Route>
            <Route path="/anlegg">
              <React.Fragment>
                <Route path="/anlegg/:activeSources?" component={ArticleView} />
                <Route
                  path="/anlegg/:sourceId/:articleId"
                  component={ArticleDetailView}
                />
              </React.Fragment>
            </Route>
            <Route path="/*page" component={PageView} />
            <Route component={PageView} />
          </Switch>
        </CoreLayout>
      </Router>
    );
  }
}

AppRouter.propTypes = {
  children: PropTypes.node,
};

export default AppRouter;
