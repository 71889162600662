import React from "react";
import PropTypes from "prop-types";
import MapLayers from "../components/MapLayers";
import config from "../config/kystverket";
import { Map } from "react-openlayers";
import { translate } from "react-translate";

class MapLayout extends React.Component {
  constructor(props) {
    super(props);

    if (window.innerWidth > 768) {
      this.state = {
        center: [1250000, 8450000],
        zoom: 7,
      };
    } else {
      this.state = {
        center: [950000, 8400000],
        zoom: 5,
      };
    }
  }

  render() {
    const { children, t } = this.props;
    const { center, zoom } = this.state;

    return (
      <Map
        track
        center={center}
        zoom={zoom}
        minZoom={3}
        projDefs={[
          {
            epsg: "EPSG:32633",
            def: "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs",
          },
          {
            epsg: "EPSG:4326",
            def: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
          },
        ]}
        baatUrl={config.adaptiveUrl}
        zoomInTipLabel={t("zoomInTipLabel")}
        zoomOutTipLabel={t("zoomOutTipLabel")}
        trackTipLabel={t("trackTipLabel")}
      >
        <MapLayers />
        {children}
      </Map>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

export default translate("MapLayout")(MapLayout);
