import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ArticleFeatureRenderer from "./ArticleFeatureRenderer";
import config from "../config/kystverket";

class ArticleLayers extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    layers: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor() {
    super();
    this.onArticleOpen = this.onArticleOpen.bind(this);
  }

  onArticleOpen(article) {
    const { history } = this.props;
    if (config.site === 4)
      history.push("/anlegg/" + article.id.replace("_", "/"));
    else history.push("/en/facilities/" + article.id.replace("_", "/"));
    window.scroll(0, 0);
  }

  render() {
    return (
      <ArticleFeatureRenderer
        activeSources={this.props.layers}
        onArticleOpen={this.onArticleOpen}
      />
    );
  }
}

export default withRouter(ArticleLayers);
